
import axios from '@axios';
import { findField } from "@/shared"

export default {
  namespaced: true,
  state: {
    model: {
      entities: [],
      objectTables: [],
      objectForms: [],
      menu:[],
      maps:[],
      calendars:[],
      pipelines: [],
      pipelineMap: {},
      fieldMap: {},
      fieldDisplayNameMap: {}
    }
  },
  getters: {
    model: state => state.model,
    entities: state => state.model.entities,
    objectTables: state => state.model.objectTables,
    objectForms: state => state.model.objectForms,
    menu: state => state.model.menu,
    maps: state => state.model.maps,
    calendars: state => state.model.calendars,
    fieldMap: state => state.model.fieldMap,
    fieldDisplayNameMap: state => state.model.fieldDisplayNameMap,
    pipelineMap: state => state.model.pipelineMap,
    pipelines: state => state.model.pipelines,
  },
  mutations: {
    setModel(state, val) {
      let o = val.entities;
      o.sort((a, b) => a.name.localeCompare(b.name));

      o.forEach(entity => {
        if (entity.displayValueFieldId) {
          entity.displayValueFieldName = entity.fields.find(x => x.id === entity.displayValueFieldId)?.name || null;
          entity.displayValueField = entity.fields.find(x => x.id === entity.displayValueFieldId) || null;
        }

        if (entity.scripts && entity.scripts.length > 0) {
          try {
            const clientScripts = entity.scripts.filter(x => !x.disabled).filter(x => x.scriptType === 'ExecutionOnClientSide' || x.scriptType === 'Both');
            if(clientScripts && clientScripts.length > 0) {
              clientScripts.forEach(script => {
                const func = new Function('item', script.code); 
                script.func = func;
              });
            }
          } catch(e) {
            console.error(e);
          }
          
        }

        const useInsteadOfNumberField = entity.fields.find(x => x.useInsteadOfNumber);
        if(useInsteadOfNumberField) {
          entity.useInsteadOfNumberField = useInsteadOfNumberField;
          entity.$getCode = (v) => {
            return v[useInsteadOfNumberField.name] || v.Number;
          }
        } else {
          entity.$getCode = (v) => {
            return v.Number;
          }
        }
      });

      val.objectTables.forEach(table => {
        const e = o.find(x => x.id === table.entity);
        table.columns.forEach(column => {
          column.originalCaption = column.caption;
          if(column.type === 'Field') {
            const field = findField(e, column.field);
            column.caption = column.caption || field?.field?.displayName;
          } else  if(column.type === 'Pipeline') {
            column.caption =  column.caption || val.pipelines.find(x => x.id === column.pipeline)?.displayName || '???';
          }
         
        });
      });

      val.objectForms.forEach(form => {
        const e = o.find(x => x.id === form.entity);
        form.items.forEach(item => {
          if(item.type === 'Field') {
            const field = findField(e, item.field);
            item.originalLabel = item.label;
            item.label = item.label || field?.field?.displayName || val.pipelines.find(x => x.id === item.field)?.displayName || '???';
          } else if(item.type === 'Pipeline') {
            item.originalLabel = item.label;
            item.label = item.label || val.pipelines.find(x => x.id === item.pipeline)?.displayName || '???';
          }
        });
      });

      const fieldMap = {};
      o.forEach(e => {
        e.fields.forEach(f => fieldMap[f.id] = f.name );
      });
      const fieldDisplayNameMap = {};
      o.forEach(e => {
        e.fields.forEach(f => fieldDisplayNameMap[f.id] = f.displayName );
      });


      const pipelineMap = {};
      val.pipelines.forEach(e => {
        pipelineMap[e.id] = e;
      });
      
      state.model = {
        entities: o,
        fieldMap: fieldMap,
        fieldDisplayNameMap: fieldDisplayNameMap,
        pipelineMap: pipelineMap,
        objectTables: val.objectTables,
        objectForms: val.objectForms,
        menu: val.menu,
        maps: val.maps,
        calendars: val.calendars,
        pipelines: val.pipelines
      };
    }
  },
  actions: {
    async createSearchField({dispatch}, { data, entityId } ){
      await axios.post(`/configuration/entities/${entityId}/full-text-search-fields`, data);
      await dispatch('load');
    },
    async updateSearchField({dispatch}, { data, entityId, fieldId } ){
      await axios.put(`/configuration/entities/${entityId}/full-text-search-fields/${fieldId}`, data);
      await dispatch('load');
    },
    async deleteSearchField({dispatch}, { fieldId, entityId } ){
      await axios.delete(`/configuration/entities/${entityId}/full-text-search-fields/${fieldId}`);
      await dispatch('load');
    },
    async updateSearchFieldPositions({dispatch, state}, { entityId, data } ){
      await axios.put(`/configuration/entities/${entityId}/full-text-search-fields-position`, data);
      await dispatch('load');
    },

    async createEntityUniqueConstraint({dispatch}, { data, entityId } ){
      await axios.post(`/configuration/entities/${entityId}/unique-constraints`, data);
      await dispatch('load');
    },
    async updateEntityUniqueConstraint({dispatch}, { data, entityId, constraintId } ){
      await axios.put(`/configuration/entities/${entityId}/unique-constraints/${constraintId}`, data);
      await dispatch('load');
    },
    async deleteEntityConstraint({dispatch}, { constraintId, entityId } ){
      await axios.delete(`/configuration/entities/${entityId}/constraints/${constraintId}`);
      await dispatch('load');
    },
    async updateEntityConstraintPositions({dispatch, state}, { entityId, data } ){
      await axios.put(`/configuration/entities/${entityId}/constraints-position`, data);
      await dispatch('load');
    },

    async createEntityScript({dispatch}, { data, entityId } ){
      await axios.post(`/configuration/entities/${entityId}/scripts`, data);
      await dispatch('load');
    },
    async updateEntityScript({dispatch}, { data, entityId, scriptId } ){
      await axios.put(`/configuration/entities/${entityId}/scripts/${scriptId}`, data);
      await dispatch('load');
    },
    async deleteEntityScript({dispatch}, { scriptId, entityId } ){
      await axios.delete(`/configuration/entities/${entityId}/scripts/${scriptId}`);
      await dispatch('load');
    },
    async updateEntityScriptPositions({dispatch, state}, { entityId, data } ){
      await axios.put(`/configuration/entities/${entityId}/scripts-position`, data);
      await dispatch('load');
    },

    async createPredicate({dispatch}, { data, entityId } ){
      await axios.post(`/configuration/entities/${entityId}/predicates`, data);
      await dispatch('load');
    },
    async updatePredicate({dispatch}, { data, entityId, predicateId } ){
      await axios.put(`/configuration/entities/${entityId}/predicates/${predicateId}`, data);
      await dispatch('load');
    },
    async deletePredicate({dispatch}, { predicateId, entityId } ){
      await axios.delete(`/configuration/entities/${entityId}/predicates/${predicateId}`);
      await dispatch('load');
    },
    async updateEntityPredicatePositions({dispatch, state}, { entityId, data } ){
      await axios.put(`/configuration/entities/${entityId}/predicates-position`, data);
      await dispatch('load');
    },
    async createField({dispatch}, { data, entityId } ){
      await axios.post(`/configuration/entities/${entityId}/fields`, data);
      await dispatch('load');
    },
    async updateField({dispatch}, { data, entityId, fieldId } ){
      await axios.put(`/configuration/entities/${entityId}/fields/${fieldId}`, data);
      await dispatch('load');
    },
    async deleteField({dispatch}, { fieldId, entityId } ){
      await axios.delete(`/configuration/entities/${entityId}/fields/${fieldId}`);
      await dispatch('load');
    },
    async updateEntityFieldPositions({dispatch, state}, { entityId, data } ){
      await axios.put(`/configuration/entities/${entityId}/fields-position`, data);
      await dispatch('load');
    },
    async createEntity({dispatch}, {data} ){
      const resp = await axios.post('/configuration/entities', data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateEntity({dispatch, state}, { data } ){
      await axios.put(`/configuration/entities`, data);
      await dispatch('load');
    },
    async deleteEntity({dispatch}, { id } ){
      await axios.delete(`/configuration/entities/${id}`);
      await dispatch('load');
    },
    async updateEntityPermissions({dispatch}, { entityId, data } ) {
      await axios.put(`/configuration/entities/${entityId}/permissions`, data );
      await dispatch('load');
    },

    /* Pipelines  >>> */

    async createPipeline({dispatch}, {data} ){
      const resp = await axios.post('/configuration/pipelines', data);
      await dispatch('load');
      return resp.data.id;
    },
    async updatePipeline({dispatch, state}, { data,  id } ){
      await axios.put(`/configuration/pipelines/${id}`, data);
      await dispatch('load');
    },
    async deletePipeline({dispatch}, { id } ){
      await axios.delete(`/configuration/pipelines/${id}`);
      await dispatch('load');
    },
    async updatePipelinePositions({dispatch, state}, { data } ){
      await axios.put(`/configuration/pipelines-position`, data);
      await dispatch('load');
    },

    async createPipelineStage({dispatch}, { data, pipelineId } ){
      const resp = await axios.post(`/configuration/pipelines/${pipelineId}/stages`, data);
      await dispatch('load');
      return resp.data.id;
    },
    async updatePipelineStage({dispatch, state}, { data, pipelineId, id } ){
      await axios.put(`/configuration/pipelines/${pipelineId}/stages/${id}`, data);
      await dispatch('load');
    },
    async deletePipelineStage({dispatch}, { pipelineId, id } ){
      await axios.delete(`/configuration/pipelines/${pipelineId}/stages/${id}`);
      await dispatch('load');
    },
    async updatePipelineStagePositions({dispatch, state}, { pipelineId, data } ){
      await axios.put(`/configuration/pipelines/${pipelineId}/stages-position`, data);
      await dispatch('load');
    },

    async createCardStyle({dispatch}, { data, pipelineId } ){
      const resp = await axios.post(`/configuration/pipelines/${pipelineId}/card-styles`, data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateCardStyle({dispatch, state}, { data, pipelineId, id } ){
      await axios.put(`/configuration/pipelines/${pipelineId}/card-styles/${id}`, data);
      await dispatch('load');
    },
    async deleteCardStyle({dispatch}, { pipelineId, id } ){
      await axios.delete(`/configuration/pipelines/${pipelineId}/card-styles/${id}`);
      await dispatch('load');
    },
    async updateCardStylePositions({dispatch, state}, { pipelineId, data } ){
      await axios.put(`/configuration/pipelines/${pipelineId}/card-styles-position`, data);
      await dispatch('load');
    },
 
     /* Pipelines  <<< */

    /* Object Forms Methods >>> */
    async createObjectForm({dispatch}, {data} ){
      const resp = await axios.post('/configuration/object-forms', data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateObjectForm({dispatch, state}, { id, data } ){
      await axios.put(`/configuration/object-forms/` + id, data);
      await dispatch('load');
    },
    async deleteObjectForm({dispatch}, { id } ){
      await axios.delete(`/configuration/object-forms/${id}`);
      await dispatch('load');
    },
    async createObjectFormItem({dispatch}, {data, formId} ){
      const resp = await axios.post(`/configuration/object-forms/${formId}/items`, data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateObjectFormItem({dispatch, state}, { formId, data } ){
      await axios.put(`/configuration/object-forms/${formId}/items`, data);
      await dispatch('load');
    },
    async updateObjectFormItemPositions({dispatch, state}, { formId, data } ){
      await axios.put(`/configuration/object-forms/${formId}/items-position`, data);
      await dispatch('load');
    },
    async deleteObjectFormItem({dispatch}, { formId, id } ){
      await axios.delete(`/configuration/object-forms/${formId}/items/${id}`);
      await dispatch('load');
    },

    async createObjectFormTab({dispatch}, {data, formId} ){
      const resp = await axios.post(`/configuration/object-forms/${formId}/tabs`, data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateObjectFormTab({dispatch, state}, { formId, data, id } ){
      await axios.put(`/configuration/object-forms/${formId}/tabs/${id}`, data);
      await dispatch('load');
    },
    async updateObjectFormTabPositions({dispatch, state}, { formId, data } ){
      await axios.put(`/configuration/object-forms/${formId}/tabs-position`, data);
      await dispatch('load');
    },
    async deleteObjectFormTab({dispatch}, { formId, id } ){
      await axios.delete(`/configuration/object-forms/${formId}/tabs/${id}`);
      await dispatch('load');
    },
    
    /* Object Forms Methods <<< */

    /* Object Tables Methods >>> */
    async createObjectTable({dispatch}, {data} ){
      const resp = await axios.post('/configuration/object-tables', data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateObjectTable({dispatch, state}, { id, data } ){
      await axios.put(`/configuration/object-tables/` + id, data);
      await dispatch('load');
    },
    async deleteObjectTable({dispatch}, { id } ){
      await axios.delete(`/configuration/object-tables/${id}`);
      await dispatch('load');
    },

    async createObjectTableColumn({dispatch}, {data, tableId} ){
      const resp = await axios.post(`/configuration/object-tables/${tableId}/columns`, data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateObjectTableColumn({dispatch, state}, { tableId, id, data } ){
      await axios.put(`/configuration/object-tables/${tableId}/columns/${id}`, data);
      await dispatch('load');
    },
    async updateObjectTableColumnPositions({dispatch, state}, { tableId, data } ){
      await axios.put(`/configuration/object-tables/${tableId}/columns-position`, data);
      await dispatch('load');
    },
    async deleteObjectTableColumn({dispatch}, { tableId, id } ){
      await axios.delete(`/configuration/object-tables/${tableId}/columns/${id}`);
      await dispatch('load');
    },

    async createObjectTableRowStyle({dispatch}, {data, tableId} ){
      const resp = await axios.post(`/configuration/object-tables/${tableId}/row-styles`, data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateObjectTableRowStyle({dispatch, state}, { tableId, id, data } ){
      await axios.put(`/configuration/object-tables/${tableId}/row-styles/${id}`, data);
      await dispatch('load');
    },
    async updateObjectTableRowStylePositions({dispatch, state}, { tableId, data } ){
      await axios.put(`/configuration/object-tables/${tableId}/row-styles-position`, data);
      await dispatch('load');
    },
    async deleteObjectTableRowStyle({dispatch}, { tableId, id } ){
      await axios.delete(`/configuration/object-tables/${tableId}/row-styles/${id}`);
      await dispatch('load');
    },
    /* Object Tables Methods <<< */

    /* Object Menu Methods >>> */
    async createMenuItem({dispatch}, {data} ){
      const resp = await axios.post(`/configuration/menu`, data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateMenuItem({dispatch}, {data, id} ){
      const resp = await axios.put(`/configuration/menu/${id}`, data);
      await dispatch('load');
      return resp.data.id;
    },
    async deleteMenuItem({dispatch}, { id } ){
      await axios.delete(`/configuration/menu/${id}`);
      await dispatch('load');
    },
    async updateMenuItemPositions({dispatch, state}, { data } ){
      await axios.put(`/configuration/menu/items-position`, data);
      await dispatch('load');
    },
    /* Object Menu Methods <<< */

    /* Maps Methods >>> */
    async createMap({dispatch}, {data} ){
      const resp = await axios.post('/configuration/maps', data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateMap({dispatch, state}, { id, data } ){
      await axios.put(`/configuration/maps/` + id, data);
      await dispatch('load');
    },
    async deleteMap({dispatch}, { id } ){
      await axios.delete(`/configuration/maps/${id}`);
      await dispatch('load');
    },
    async createMapEntity({dispatch}, {data, mapId} ){
      const resp = await axios.post(`/configuration/maps/${mapId}/entities`, data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateMapEntity({dispatch, state}, { id, data, mapId } ) {
      await axios.put(`/configuration/maps/${mapId}/entities/${id}`, data);
      await dispatch('load');
    },
    async deleteMapEntity({dispatch}, { id, mapId } ){
      await axios.delete(`/configuration/maps/${mapId}/entities/${id}`);
      await dispatch('load');
    },
    async updateMapEntityPositions({dispatch, state}, { data, mapId } ) {
      await axios.put(`/configuration/maps/${mapId}/entities-position`, data);
      await dispatch('load');
    },
    /* Maps Methods <<< */

    /* Calendars Methods >>> */
    async createCalendar({dispatch}, {data} ){
      const resp = await axios.post('/configuration/calendars', data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateCalendar({dispatch, state}, { id, data } ){
      await axios.put(`/configuration/calendars/` + id, data);
      await dispatch('load');
    },
    async deleteCalendar({dispatch}, { id } ){
      await axios.delete(`/configuration/calendars/${id}`);
      await dispatch('load');
    },
    async createCalendarEntity({dispatch}, {data, calendarId} ){
      const resp = await axios.post(`/configuration/calendars/${calendarId}/entities`, data);
      await dispatch('load');
      return resp.data.id;
    },
    async updateCalendarEntity({dispatch, state}, { id, data, calendarId } ) {
      await axios.put(`/configuration/calendars/${calendarId}/entities/${id}`, data);
      await dispatch('load');
    },
    async deleteCalendarEntity({dispatch}, { id, calendarId } ){
      await axios.delete(`/configuration/calendars/${calendarId}/entities/${id}`);
      await dispatch('load');
    },
    async updateCalendarEntityPositions({dispatch, state}, { data, calendarId } ) {
      await axios.put(`/configuration/calendars/${calendarId}/entities-position`, data);
      await dispatch('load');
    },
    /* Calendars Methods <<< */

    async load({ commit }) {
      try {
        const resp = await axios.get(`configuration`);
        commit('setModel', resp.data);
      } finally {
       
      }
    }
  },
}
